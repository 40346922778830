<template>
  <div>
    <div class="section wp100 head">
      <div class="headText">
        <div class="f48 mt20">一站式服务体系 管家式服务保障</div>
        <div class="f36 mt40">
          专家级顾问团队，提供极速、周到的咨询服务与技术支持
        </div>
      </div>
    </div>
    <div class="section section2 pt100">
      <div class="wd1145 flex justify-between align-center">
        <div class="wd200 flex justify-between align-center">
          <img
            class="img228"
            src="../../../assets/images/product/cusApplet/Frame 130.png"
            alt=""
            srcset=""
          />
        </div>
        <div>
          <img
            src="../../../assets/images/product/cusApplet/Arrow - Right Circle.png"
            alt=""
            class="img40"
          />
        </div>
        <div class="wd200 flex justify-between align-center">
          <img
            class="img228"
            src="../../../assets/images/product/cusApplet/Frame 131.png"
            alt=""
            srcset=""
          />
        </div>
        <div>
          <img
            src="../../../assets/images/product/cusApplet/Arrow - Right Circle.png"
            alt=""
            class="img40"
          />
        </div>
        <div class="wd200 flex justify-between align-center">
          <img
            class="img228"
            src="../../../assets/images/product/cusApplet/Frame 132.png"
            alt=""
            srcset=""
          />
        </div>
      </div>
      <div class="wd1145 flex justify-between align-center">
        <div class="wd200">
          <div class="f18 fw700 mb15">前期调研</div>
          <p class="f16">客户需求沟通</p>
          <p class="f16">现场业务了解梳理</p>
        </div>
        <div class="wd200">
          <div class="f18 fw700 mb15">方案设计</div>
          <p class="f16">客户需求沟通</p>
          <p class="f16">现场业务了解梳理</p>
        </div>
        <div class="wd200">
          <div class="f18 fw700 mb15">上线准备</div>
          <p class="f16">客户需求沟通</p>
          <p class="f16">现场业务了解梳理</p>
        </div>
      </div>
      <div class="wd1145 flex justify-between align-center">
        <div class="wd200 flex justify-between align-center">
          <img
            class="img228"
            src="../../../assets/images/product/cusApplet/Frame 133.png"
            alt=""
            srcset=""
          />
        </div>
        <div>
          <img
            src="../../../assets/images/product/cusApplet/Arrow - Right Circle.png"
            alt=""
            class="img40"
          />
        </div>
        <div class="wd200 flex justify-between align-center">
          <img
            class="img228"
            src="../../../assets/images/product/cusApplet/Frame 134.png"
            alt=""
            srcset=""
          />
        </div>
        <div>
          <img
            src="../../../assets/images/product/cusApplet/Arrow - Right Circle.png"
            alt=""
            class="img40"
          />
        </div>
        <div class="wd200 flex justify-between align-center">
          <img
            class="img228"
            src="../../../assets/images/product/cusApplet/Frame 135.png"
            alt=""
            srcset=""
          />
        </div>
      </div>
      <div class="wd1145 flex justify-between align-center">
        <div class="wd200">
          <div class="f18 fw700 mb15">售后跟踪</div>
          <p class="f16">定期回访，线上线下</p>
          <p class="f16">管家式跟踪服务快速响应</p>
        </div>
        <div class="wd200">
          <div class="f18 fw700 mb15">系统培训</div>
          <p class="f16">点对点和重点岗位培训，</p>
          <p class="f16">协助角色权限分配</p>
        </div>
        <div class="wd200">
          <div class="f18 fw700 mb15">专业实施</div>
          <p class="f16">项目经理全程跟踪进度</p>
          <p class="f16">确保系统切换无忧</p>
        </div>
      </div>
    </div>
    <div class="section section3 pt100">
      <div class="auto">
        <div class="f36" style="color:#fff">“管家式”服务保障</div>
      </div>
      <div class="wd886 flex justify-between align-center" style="margin-top:70px">
        <div class="wd240">
            <img src="../../../assets/images/product/cusApplet/Frame4.png" alt="" srcset="">
        </div>
        <div class="wd240">
            <img src="../../../assets/images/product/cusApplet/Frame3.png" alt="" srcset="">
        </div>
        <div class="wd240">
            <img src="../../../assets/images/product/cusApplet/Frame2.png" alt="" srcset="">
        </div>
      </div>
      <div class="wd886 flex justify-between" style="margin-top:20px">
        <div class="wd240">
            <p>每月定期线上产品培训</p>
        </div>
        <div class="wd240">
            <p>求工单48小时内响应</p>
        </div>
        <div class="wd240">
            <p>专业本地团队上门实施指导</p>
        </div>
      </div>
      <div class="wd886 flex justify-between align-center" style="margin-top:70px">
        <div class="wd240">
            <img src="../../../assets/images/product/cusApplet/Frame5.png" alt="" srcset="">
        </div>
        <div class="wd240">
            <img src="../../../assets/images/product/cusApplet/Frame1.png" alt="" srcset="">
        </div>
        <div class="wd240">
            <img src="../../../assets/images/product/cusApplet/Frame.png" alt="" srcset="">
        </div>
      </div>
      <div class="wd886 flex justify-between" style="margin-top:20px">
        <div class="wd240">
            <p>定期本地用户线下培训、沙龙</p>
        </div>
        <div class="wd240">
            <p>7*12小时专业在线客户响应支持</p>
        </div>
        <div class="wd240">
            <p>品质保障</p>
        </div>
      </div>
    </div>
    <div class="section section4 pt100">
        <div class="auto">
            <div class="f36">扫码体验云蓝客用教程小程序</div>
        </div>
        <div class="code">
            <img src="../../../assets/images/download/saomaCode.jpg" alt="" srcset="" style="width:200px;height:200px">
        </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.head {
  height: 500px;
  background-image: url("../../../assets/images/product/cusApplet/Group 82.png");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.headText {
  widows: 1000px;
  height: 200px;
  position: absolute;
  z-index: 9;
  top: 170px;
  left: 360px;
  color: #fff;
}
.section2 {
  height: 919px;
  width: 100%;
  box-sizing: border-box;
}
.wd1145 {
  width: 1145px;
  margin: 0 auto;
  text-align: center;
}
.wd200 {
  width: 200px;
  height: 200px;
}
.img228 {
  width: 228px;
  height: 152px;
}
.img40 {
  width: 40px;
  height: 40px;
}
.section3 {
  width: 100%;
  height: 680px;
  background-color: #0084ff;
  box-sizing: border-box;
}
.auto {
  width: 500px;
  margin: 0 auto;
  text-align: center;
}
.wd886{
    width: 886px;
    margin: 0 auto;
    text-align: center;
    color: #fff;
}
.wd240 {
  width: 240px;
}
.wd240 img{
    width: 100px;
    height: 100px;
}
.section4 {
  width: 100%;
  height: 529px;
  box-sizing: border-box;
  background-image: url("../../../assets/images/product/cusApplet/Group 42.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.code{
    width: 100%;
    text-align: center;
    margin-top: 60px;
}
</style>